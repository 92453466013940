import * as React from "react";
import { CustomerServiceCom } from "./CustomerServiceCom";
import * as DataType from '..//../../common/CustomerServiceType';
import * as _ from 'lodash';

type IProps={customerServiceDataMap:DataType.CustomerServiceDataMap};
export class CustomerService extends React.Component<IProps>{
    
    render(){
        let customerServiceComs=[];
        let customerServiceDataMap=this.props.customerServiceDataMap;
        let typeMap=new Map();
        typeMap.set("phone","电话");
        typeMap.set("qq","QQ");
        typeMap.set("weixin","微信");
        typeMap.forEach((title,dataType)=>{
            if(_.has(customerServiceDataMap,dataType) && !_.isEmpty(customerServiceDataMap[dataType])){
                customerServiceComs.push(<CustomerServiceCom dataType={dataType} customerServiceDataList={customerServiceDataMap[dataType]} />);
            }
        })
       
        
        return(
            <>
                {customerServiceComs}
            </>
        );
    }
}