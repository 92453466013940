import * as React from 'react';
import * as ReactDOM from "react-dom";
import { CustomerService} from "./components/CustomerService";
import * as _ from 'lodash';
import * as DateType from '../../common/CustomerServiceType';
declare var customerServiceDataMap:DateType.CustomerServiceDataMap;
(function(){
    if(_.isEmpty(customerServiceDataMap)){
        return;
    }
    ReactDOM.render(
        <CustomerService customerServiceDataMap={customerServiceDataMap} />,
        document.getElementById("right_toolbar")
    )
})();