import * as React  from "react";
var QRCode=require('qrcode.react');
import * as _ from 'lodash';
import * as DateType from '../../../common/CustomerServiceType';
type IProps={dataType:string,customerServiceDataList:DateType.CustomerServiceDataList};
export class CustomerServiceCom extends React.Component<IProps> {
    private renderFunc;
    constructor(props:IProps){
        super(props);
        if(props.dataType=="phone"){
            this.renderFunc=this.rendrPhone;
        }if(props.dataType=="qq"){
            this.renderFunc=this.renderQQ;
        }else if(props.dataType=="weixin"){
            this.renderFunc=this.renderWeixin;
        }
    }

    private rendrPhone(node:DateType.CustomerServiceData,index=null){
        if(index===null){
            index=1;
        }
        let text=_.isEmpty(node.name)?node.value:(node.value+"("+node.name+")");
        return <div key={index}>{text}</div>;
        
    }
    private renderQQ(node:DateType.CustomerServiceData,index=null){
        if(index===null){
            index=1;
        }
        let text=_.isEmpty(node.name)?node.value:node.name;
        return <div key={index}><a  key="1" href={"tencent://message/?uin="+node.value}>{node.value}</a></div>;
            
    }
    private renderWeixin(node:DateType.CustomerServiceData,index=null){
        if(index===null){
            return <div key="1"><QRCode key="1" value={node.value} /></div>
        }
        return <div key={index}>
            <div key="name" className="name">{node.name}</div>
            <div><QRCode key="1" value={node.value} /></div>
        </div>;

    }
    render(){
        let {dataType,customerServiceDataList}=this.props;
        let list=[],_this=this;
        if(_.isEmpty(customerServiceDataList)){
            return null;
        }else if(customerServiceDataList.length==1){
            list.push(_this.renderFunc(customerServiceDataList[0]));
        }else{
            customerServiceDataList.map((node,index)=>{
                list.push(_this.renderFunc(node,index))
            });
        }
        
        return(
            <div key={dataType} className={"toolbar-item "+dataType}>
                <div key="main" className="main"><i className={"iconfont icon-"+dataType+"-outline"}/></div>
                <div key="pop" className="pop">{list}</div>
            </div>
        )
    }
}